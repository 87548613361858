import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import axios from "axios";
import { setLoginData } from "../../helpers/helpers";
import queryString from "query-string";
import { setAgencyData, callTrackerCompany, saveTracker } from "../../helpers/agencyTracker";

import "./asiacell-catch-page.scss"
import { useHistory } from "react-router";

//const history = useHistory();



class asiacellCatchPage extends Component {
  constructor() {
    super();

  }
  async componentDidMount() {
    this.fireAPI();

    // setTimeout(() => {
    //   this.fireAPI();
    // }, 5000);

  }

  fireAPI = async () => {

    //window.gtag('event', 'conversion', {'send_to': 'AW-463530816/imfbCNzsqO8BEMDWg90B'});

    const data = queryString.parse(window.location.search)
   //console.log(data);
    if (data.CGMSISDN) {

      //setInterval
      // if (data.CGStatus == 0) {
      //  // window.fbq('track', 'Subscribe');
      // }
      //  await axios.get(`http://www.api.playit.mobi/api/v1/getToken/${data.msisdn}/1`)
      //     .then(res => {
      //      //console.log(res);

      var datag = {};
      if (localStorage.getItem('tracker')) {
        var op_id = 'lFZazRgWkL';
        var phoneid = data.CGMSISDN;
        // if (phoneid.startsWith("011") || phoneid.startsWith("2011")) {
        //   op_id = Operators.egypt011;
        // }
        // if (phoneid.startsWith("012") || phoneid.startsWith("2012")) {
        //   op_id = Operators.egypt012;
        // }
        switch (localStorage.getItem("trackerCompany")) {
          case 'InhouseSource1':
            datag = {
              key: 'inhouse1_click_id',
              company: localStorage.getItem("trackerCompany"),
              value: localStorage.getItem('tracker'),
              identifierKey: 'id',
              identifierValue: op_id,
              phoneNum: data.CGMSISDN
            };

            break;
          case 'InhouseSource2':
            datag = {
              key: 'inhouse2_click_id',
              company: localStorage.getItem("trackerCompany"),
              value: localStorage.getItem('tracker'),
              identifierKey: 'id',
              identifierValue: op_id,
              phoneNum: data.CGMSISDN
            };
            break;
          case 'OlimobCPA':
            datag = {
              key: 'eoliclick',
              company: localStorage.getItem("trackerCompany"),
              value: localStorage.getItem('tracker'),
              identifierKey: 'id',
              identifierValue: op_id,
              phoneNum: data.CGMSISDN
            };
            break;
          case 'digiTantra':
            datag = {
              key: 'erid',
              company: localStorage.getItem("trackerCompany"),
              value: localStorage.getItem('tracker'),
              identifierKey: 'id',
              identifierValue: op_id,
              phoneNum: data.CGMSISDN
            };
            break;
          case 'krayons':
            datag = {
              key: 'kcclick',
              company: localStorage.getItem("trackerCompany"),
              value: localStorage.getItem('tracker'),
              identifierKey: 'id',
              identifierValue: op_id,
              phoneNum: data.CGMSISDN
            };
            break;
          case 'youmi':
            datag = {
              key: 'yerid',
              company: localStorage.getItem("trackerCompany"),
              value: localStorage.getItem('tracker'),
              identifierKey: 'id',
              identifierValue: op_id,
              phoneNum: data.CGMSISDN
            };
            break;
          case 'trafficCompany':
            datag = {
              'key': 'etracker',
              company: localStorage.getItem("trackerCompany"),
              value: localStorage.getItem('tracker'),
              identifierKey: 'id',
              identifierValue: op_id,
              phoneNum: data.CGMSISDN
            };
            break;
          case 'ArmorAds':
            datag = {
              key: 'earmclick_id',
              company: localStorage.getItem("trackerCompany"),
              value: localStorage.getItem('tracker'),
              identifierKey: 'id',
              identifierValue: op_id,
              phoneNum: data.CGMSISDN
            };
            break;
          case 'MOBIPIUM':
            datag = {
              key: 'merid',
              company: localStorage.getItem("trackerCompany"),
              value: localStorage.getItem('tracker'),
              identifierKey: 'id',
              identifierValue: op_id,
              phoneNum: data.CGMSISDN
            };

            break;
          case 'Propellar':
            datag = {
              key: 'visitor_id',
              company: localStorage.getItem("trackerCompany"),
              value: localStorage.getItem('tracker'),
              identifierKey: 'id',
              identifierValue: op_id,
              phoneNum: data.CGMSISDN
            };

            break;
          case 'Mobidea':
            datag = {
              key: 'click_id',
              company: localStorage.getItem("trackerCompany"),
              value: localStorage.getItem('tracker'),
              identifierKey: 'id',
              identifierValue: op_id,
              phoneNum: data.CGMSISDN
            };

            break;
          case 'Rich':
            datag = {
              key: 'key',
              company: localStorage.getItem("trackerCompany"),
              value: localStorage.getItem('tracker'),
              identifierKey: 'id',
              identifierValue: op_id,
              phoneNum: data.CGMSISDN
            };
            break;

          default:
            break;
        }
        datag = {
          key: 'parameter',
          company: localStorage.getItem("trackerCompany"),
          value: localStorage.getItem('tracker'),
          identifierKey: 'id',
          identifierValue: op_id,
          phoneNum: data.CGMSISDN
        };

      }

      saveTracker(datag);

      await axios.get(`http://www.api.playit.mobi/api/v1/getToken/${data.msisdn}/1`)
        .then(res => {
          var loged = setLoginData(res.data.user_id, res.data.token, res.data.user_name, res.data.photo, res.data.operator_id, res.data.user_email, res.data.msisdn);
          if (loged && data.CGStatus == 0) {
           //console.log("log1")
            this.props.history.push(`/thank-asiacell?trafficsource=${localStorage.getItem("trackerCompany")}`)
           // history.push(`/thank-asiacell?trafficsource=${localStorage.getItem("trackerCompany")}`)
           // window.location.href = "/";
         }
         else if ((!loged && data.CGStatus == 0) )
         {
         //console.log("log2")

          this.props.history.push(`/thank-asiacell?trafficsource=${localStorage.getItem("trackerCompany")}`)
        }
         else
         {
         //console.log("log3")

          window.location.href = "/";
         }
          // if (loged) {
          //    window.location.href = "/";
          // }

        }).catch(error => {
         //console.log(error);

        })


      // setLoginData(res.data.user_id, res.data.token, res.data.user_name, res.data.photo, res.data.operator_id, res.data.user_email, res.data.msisdn);
      // window.location.href ="/";
      // }).catch(error => {
      //  //console.log(error);
      //   window.location.href = "/";
      // })

    } else {
      window.location.href = "/";
    }
  }
  render() {

    return (
     
      <div></div>



    );
  }
}
export default withTranslation()(asiacellCatchPage);

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import FloatingAlert from "../../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../../helpers/bgSliders";
import "./SignupPage.styled";
import ZainiqSignupPageDisplay from "./SignupPage.styled";
import { setLoginData } from "../../../helpers/helpers";
import { Trans, withTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { setAgencyData, saveTracker } from "../../../helpers/agencyTracker";



const randomBg = bgSliders.getRandomBg();
function ZainiqSignupPage({ t }) {
  const [activeData, setActiveData] = useState("");
  const [checkPhone, setCheckPhone] = useState(false);

  const [showverif, setShowverif] = useState(false);
  const [verifyCode, setVerifyCode] = useState(false);
  const [errorMesg, setErrorMesg] = useState(false);
  const [verfiErrorMesg, setVerfiErrorMesg] = useState(false);
  const [errorresverify, seterrorresverify] = useState("");

  const [errorMesgSubscribe, setErrorMesgSubscribe] = useState("");
  const [signupPlan, setSignupPlan] = useState("d");
  const [otpid, setotpid] = useState("");
  const [showLoading, setshowLoading] = useState(false);



  const history = useHistory();
  const [alert, setAlert] = useState(false);


  const plans = [
    {
      plan: "d",
      name: "Daily"
    },
    {
      plan: "w",
      name: "Weekly"
    }
  ];

  useEffect(() => {
    // async function fetchit() {
    //   var linkheader = "http://mtnng.playit.mobi/checkheader.php";
    //   const num = await getPhoneByHeader(linkheader);
    //   var phone_h = num;
    //   setActiveData(phone_h);
    //   if (phone_h != false) {
    //     setCheckPhone(true)
    //   }
    // }
    // fetchit();
    const urlsearch = window.location.search;
    if (urlsearch !== "") {
     //console.log("tracker")
      setAgencyData(urlsearch);
    }
  }, []);

    // save tracker
    const tracker = async (GMSISDN) => {
      var datag = {};
      if (localStorage.getItem('tracker')) {
        var op_id = 'DBkuaTgj1c';
  
  
        datag = {
          key: 'parameter',
          company: localStorage.getItem("trackerCompany"),
          value: localStorage.getItem('tracker'),
          identifierKey: 'id',
          identifierValue: op_id,
          phoneNum: GMSISDN
        };
       //console.log(datag)
  
      }
      await saveTracker(datag);
  
    }
  const Subscribe = async e => {
    e.preventDefault();
    if (activeData) {
      var phon_n = activeData.toString();
      var url = "";

      url = `http://api.playit.mobi/api/v2/zainiq/gamezroid/sendpin?msisdn=${phon_n}`
      setshowLoading(true);
      axios.get(url)
        .then(res => {
          if (res.data.status === true) {
            if (res.data.user_id) {
              setLoginData(res.data.user_id, res.data.token, res.data.user_name, res.data.photo, res.data.operator_id, res.data.user_email, res.data.msisdn);
            }
            if (res.data.otpid) {
              setotpid(res.data.otpid)
            }
            setShowverif(true)
          }
          else {
            if (res.data.msg != null) {
              setErrorMesgSubscribe(res.data.msg);
              setErrorMesg(true);

            }
            else {
              setErrorMesgSubscribe(t("register.SUBSCRIBE_ERROR"));
              setErrorMesg(true);


            }
           // setShowverif(true)


          }
          setshowLoading(false);

        })
        .catch(function (error) {
          setErrorMesg(true)
          setShowverif(false)
        })
        .finally(function () {
         //console.log('complete');
        });


    }
  };


  const verify = async e => {
    e.preventDefault();
    if (verifyCode) {
      var phon_n = activeData;
      setshowLoading(true);

      axios.get(`http://api.playit.mobi/api/v2/zainiq/gamezroid/verify?msisdn=${phon_n}&pin=${verifyCode}&plan=w`)
        .then(res => {
          if (res.data.status == true) {
            setVerfiErrorMesg(false);
            //tracker(phon_n);
            if (res.data.user_id) {
              tracker(phon_n);
              setLoginData(res.data.user_id, res.data.token, res.data.user_name, res.data.photo, res.data.operator_id, res.data.user_email, res.data.msisdn);
              // window.location.href = "/";
            }
           //  window.location.href = "/";
           history.push("/catch-zainiqgamezroid")
          } else {
            if (res.data.msg != null) {
              seterrorresverify(res.data.msg);;
              setVerfiErrorMesg(true);

            }
            else {
              // setErrorMesgSubscribe(t("register.SUBSCRIBE_ERROR"));
              seterrorresverify(t("register.VERIFY_ERROR"));
              setVerfiErrorMesg(true);


            }
            // setVerfiErrorMesg(true);
            // seterrorresverify(res.data.msg);
          }
          setshowLoading(false);

        })
        .catch(function (error) {
         //console.log(error);
          // setVerfiErrorMesg(true);
          // seterrorresverify(res.data.msg);

        })
        .finally(function () {

         //console.log('complete');
        });
    }
  };

  const plansOptions = plans.map((c, k) => {
    return (
      <option key={`plan-${k}`} value={c.plan}>
        {c.name}
      </option>
    );
  });




  return (
    <ZainiqSignupPageDisplay className="">
      {alert && (
        <FloatingAlert type={"danger"}>
          In order to signup please turn your mobile data.
        </FloatingAlert>
      )}

      {errorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>{errorMesgSubscribe}</Trans>
        </FloatingAlert>
      ) : null}

      {verfiErrorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>{errorresverify}</Trans>
        </FloatingAlert>
      ) : null}

      <div className='operatorSignup'>
        <div className="formsLayout formsLayoutjawal">

          <div className='header header_gamezone'>
            <Link to="/">
              <div className='logo logo gamezoneLogo' />
            </Link>
          </div>
          {!showverif ?
            <div className="links-Section links-Sectionjawwalgamezorid">

              {/* <h3 style={{ paddingBottom: '1px' }} className="gamezoridcontent" id="gamezoridcon">
                <Trans>Your content is ready!</Trans>
              </h3> */}
              <div className="play"></div>

              <input
                type="text"
                className="input gamezoneInput"
                placeholder={t("Enter_your_phone_number")}
                value={activeData}
                disabled={checkPhone == true}
                onChange={(e) => setActiveData(e.target.value.replace(/[^0-9]/g, ""))}

              />
              <button
                id="signup"
                className="btn"
                disabled={activeData ? "" : "disabled"}
                onClick={(e) => { Subscribe(e) }}
              >
                {t("Subscribe")}
              </button>

              <div className="row text-center" >
                <div className="col-xl-4 col-lg-4 col-4 px-5">
                  <div className="games-info jawwalgames-info">
                    <span>98%</span><img src="/assets/images/subscribegamezriod/like.png" alt="img" className="iconsimg" />
                    <p><Trans>evaluation</Trans></p>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-4 px-5">
                  <div className="games-info jawwalgames-info">
                    <span>1000+</span><img src="/assets/images/subscribegamezriod/Repeat.png" alt="img" className="iconsimg" />
                    <p><Trans>game</Trans></p>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-4 px-5">
                  <div className="games-info jawwalgames-info">
                    <span>500k+</span><img src="/assets/images/subscribegamezriod/group.png" alt="img" className="iconsimg" />
                    <p><Trans>user</Trans></p>
                  </div>
                </div>
              </div>


              <div className="footer jawwalgamezoridFooter zainiqFooter">
                {!showverif ? <div>

                </div> : null}
                <div className="ncellTos jawwalgamezoridncellTos">
                  <h3><Trans>terms_and_conditions</Trans></h3>
                  <p><Trans>zainiq-condtion</Trans></p>
                  {/* <p> <Trans>JAWWALGameSUBSCRIBE_TAX</Trans> </p>
                  <p> <Trans>JAWWALGamSUBSCRIBEUNSUBSCRIBE</Trans> </p> */}
                </div>
              </div>
            </div>

            : null}

          {showverif ?
            <div className="verfiySection">
              <p><Trans>verifycodeJawwalGameszorid</Trans></p>
              <input
                type="text"
                className="input"
                value={verifyCode}
                placeholder={t("Enter_verifycode")}
                onChange={(e) => setVerifyCode(e.target.value.replace(/[^0-9]/g, ""))}

              />
              <br></br>
              <br></br> <br></br>
              <button
                className="btn"
                disabled={verifyCode ? "" : "disabled"}
                onClick={(e) => {
                  verify(e);
                }} >
                {t("Verify")}
              </button>

            </div> : null
          }

          {showLoading ?
            <div className="load">
              <div className="spinerC">
                <div className="spinner"></div>
              </div>
            </div>
            : null}




        </div>
      </div>

      {/* <div className='operatorSignup'>
        <div className="formsLayout">

          <div className='header'>
            <Link to="/">
              <div className='logo' />
            </Link>
          </div>

          {!showverif ?

            <div className="center">
              <form>
                <div>
                   <select

                    className="select"
                    defaultValue={signupPlan}
                    onChange={e => setSignupPlan(e.target.value)}
                  >

                    <Trans>{plansOptions}</Trans>
                  </select>
                

                  <input
                    type="number"
                    className="input"
                    placeholder={t("Enter_your_phone_number")}
                    value={activeData}
                    disabled={checkPhone == true}
                    onChange={e => setActiveData(e.target.value)}
                  />
                  <br></br>
                  <br></br> <br></br>
                  <button
                    className="btn"
                    disabled={activeData ? "" : "disabled"}
                    onClick={(e) => {
                      Subscribe(e);
                    }} >
                    {t("Subscribe")}
                  </button>

                </div>
              </form>
            </div>

            : null}




          {showverif ?
            <div className="verfiySection">
              <p><Trans>verifycodeSub</Trans></p>
              <input
                type="number"
                className="input"
                value={verifyCode}
                placeholder={t("Enter_verifycode")}
                onChange={e => setVerifyCode(e.target.value)}
              />
              <br></br>
              <br></br> <br></br>
              <button
                className="btn"
                disabled={verifyCode ? "" : "disabled"}
                onClick={(e) => {
                  verify(e);
                }} >
                {t("Verify")}
              </button>

            </div> : null
          }


          <div className="footer">
           
            <div className="ncellTos">

            </div>
          </div>

        </div>
      </div> */}

    </ZainiqSignupPageDisplay>
  );
}
export default withTranslation()(ZainiqSignupPage)

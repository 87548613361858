/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import FloatingAlert from "../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../helpers/bgSliders";
//import "./SignupPage.styled";
import "./EgyptSubscribePage.scss";
import EgyptSubscribePageDisplay from "./EgyptSubscribePage.styled";
import { setLoginData } from "../../helpers/helpers";
import { Trans, withTranslation } from "react-i18next";
import * as localLang from "../../i18n/i18n";
import { setAgencyData, saveTracker } from "../../helpers/agencyTracker";
import { client } from "../../auth/Client";
import { useHistory } from "react-router";

const randomBg = bgSliders.newBgegypt();
function EgyptSubscribePage({ t }) {
  const [showagin, setshowagin] = useState(false);
  const [showbutton, setshowbutton] = useState(false);
  const [errorMesg, setErrorMesg] = useState(false);
  const [showLoading, setshowLoading] = useState(false);
  const [errorMesgSubscribe, setErrorMesgSubscribe] = useState("");

  const [agree, setagree] = useState(false);
  const [showterms, setshowterms] = useState(true);
  const [subtype, setSubtype] = useState("daily");
  const [signupPlan, setSignupPlan] = useState("d");
  const [signupCompany, setsignupCompany] = useState("vodafone");
  const [codeCompany, setcodeCompany] = useState("7785");
  const [activeData, setActiveData] = useState("");
  const [showverif, setShowverif] = useState(false);
  const [verifyCode, setVerifyCode] = useState("");
  const [verfiErrorMesg, setVerfiErrorMesg] = useState(false);
  const [token, setToken] = useState("");
  const [operator, setOperator] = useState("");
  const history = useHistory();

  var lang = localStorage.getItem("i18nextLng");
  useEffect(() => {
    loadLibrary();
    const params = queryString.parse(window.location.search);

    const urlsearch = window.location.search;
    if (urlsearch !== "") {
     //console.log("tracker");
      setAgencyData(urlsearch);
    }

   //console.log(params.language);
    if (params.cbd == 1 || params.cbd == true || params.cbd == "true") {
      //setagree(true);
    }
    if (params.language) {
     //console.log("task");
      localStorage.setItem("i18nextLng", params.language);
      localLang.setlangSubscribe();
    }
    if (params.subtype) {
      setSubtype(params.subtype);
    }
  }, []);

  const loadLibrary = () => {
    //egypt portal
   //console.log("egy script");
    const noscriptEgy = document.createElement("noscript");
    // noscriptEgy.id = "egyNoscript";
    const EgyLink = document.createElement("link");

    EgyLink.href = "https://plannedy-cornance.com/d/.js?noscript=true&ourl=%22";
    EgyLink.rel = "stylesheet";

    // document.body.appendChild(noscriptEgy);

    noscriptEgy.appendChild(EgyLink);
    document.body.appendChild(noscriptEgy);

    const styleEgy = document.createElement("style");
    styleEgy.textContent = `
      .dtpcnt
      {
        opacity: 0;
      }
      `;
    document.head.appendChild(styleEgy);

    const script = document.createElement("script");
    // script.id ='egyfun'

    script.textContent = `
    (function (c, a, f, q, b, m, r, g, h, k, d, n, e, p) {
      p = "https:" === c.location.protocol ? "secure; " : "";
      c[b] ||
        ((c[b] = function (a) {
          c[b].state.callbackQueue.push(a);
        }),
        (c[b].state = { callbackQueue: [] }),
        (c[b].registerConversion = function (a) {
          c[b].state.callbackQueue.push(a);
        }),
        (function () {
          (k = /[?&]cpid(=([^&#]*)|&|#|$)/.exec(c.location.href)) &&
            k[2] &&
            ((d = k[2]),
            (n = a.cookie.match(new RegExp("(^| )vl-" + d + "=([^;]+)"))));
          var b = a.cookie.match(/(^| )vl-cid=([^;]+)/),
            l;
          "savedCid" !== r ||
            !b ||
            (d && "undefined" !== typeof d) ||
            (l = b.pop());
          g = a.createElement("script");

          h = a.scripts[0];
          g.src =
            m +
            (-1 === m.indexOf("?") ? "?" : "&") +
            "oref=" +
            f(a.referrer) +
            "&ourl=" +
            f(location[q]) +
            "&opt=" +
            f(a.title) +
            "&vtm=" +
            new Date().getTime() +
            (l ? "&cid=" + l : "") +
            (n ? "&uw=no" : "");
          h.parentNode.insertBefore(g, h);
          d &&
            ((e = new Date()),
            e.setTime(e.getTime() + 864e5),
            (a.cookie =
              "vl-" +
              d +
              "=1; " +
              p +
              "samesite=Strict; expires=" +
              e.toGMTString() +
              "; path=/"));
        })());
    })(
      window,
      document,
      encodeURIComponent,
      "href",
      "dtpCallback",
      "https://plannedy-cornance.com/d/.js",
      "savedCid"
    );
    `;

    document.body.appendChild(script);
  };

  const plans = [
    {
      plan: "d",
      name: "Daily",
    },
    {
      plan: "w",
      name: "Weekly",
    },
    {
      plan: "m",
      name: "Monthly",
    },
  ];

  const companies = [
    {
      name: "Vodafone",
      value: "vodafone",
      shortCode: "7785",
    },
    {
      name: "Etisalat",
      value: "etisalat",
      shortCode: "7786",
    },
    {
      name: "Orange",
      value: "orange",
      shortCode: "7810",
    },
    {
      name: "WE",
      value: "we",
      shortCode: "4036",
    },
  ];

  const handlclick = (e) => {
    e.preventDefault();
    setshowbutton(true);
    setshowterms(false);
  };

  // save tracker
  const tracker = async (GMSISDN) => {
    var datag = {};
    if (localStorage.getItem("tracker")) {
      var op_id = "TPET5SBsm7";

      datag = {
        key: "parameter",
        company: localStorage.getItem("trackerCompany"),
        value: localStorage.getItem("tracker"),
        identifierKey: "id",
        identifierValue: op_id,
        phoneNum: GMSISDN,
      };
     //console.log(datag);
    }
    await saveTracker(datag);
  };

  const Subscribe = async (e, operator) => {
    e.preventDefault();
    if (operator == "vodafone") {
      var url = `http://api.playit.mobi/api/v2/egypt/preparePurchas?plan=${signupPlan}&operator=${operator}`;
      setshowLoading(true);
      axios
        .get(url)
        .then((res) => {
         //console.log(res.data.status);
          if (res.data.status == true) {
            window.location.href = res.data.url;
            // window.location.href = 'http://login.mondiamediamena.com/billinggw-lcm/billing?merchantId=197&operatorId=22&redirect=http://dzo.playit.mobi/mondiaverify/W&method=subscribe&imgPath=http%3A%2F%2Fapi.playit.mobi%2Fgames-photos%2Fmondia.png&productCd=PLAYITALGERIA&subPackage=W';
          } else {
            setshowagin(true);
          }

          setshowLoading(false);
        })
        .catch(function (error) {
          setErrorMesg(true);
        })
        .finally(function () {
         //console.log("complete");
        });

      return;
    } else {
      e.preventDefault();
      if (!activeData) {
        setErrorMesg(true);
        setErrorMesgSubscribe("enter_phone_number");
      } else {
        let msisdn = activeData;
        setOperator(operator);
        var url = `http://api.playit.mobi/api/v2/egypt2/preparePurchas?plan=${signupPlan}&operator=${operator}&msisdn=${msisdn}`;
        setshowLoading(true);
        axios
          .get(url)
          .then((res) => {
           //console.log(res);
            if (res.data.status === true && res.data.token) {
              setToken(res.data.token);
              setShowverif(true);
              setErrorMesg(false);
            } else {
             //console.log("err", res.data);
              setErrorMesg(true);
              if (res.data.data.error) {
                setErrorMesgSubscribe(res.data.data.error);
              } else {
                setErrorMesgSubscribe(res.data.data.message);
              }
              // setshowagin(true);
            }

            setshowLoading(false);
          })
          .catch(function (error) {
            setErrorMesg(true);
            setshowLoading(false);

            // setErrorMesgSubscribe();
          })
          .finally(function () {
           //console.log("complete");
          });
      }
    }

   //console.log("operator", operator, signupPlan);
  };

  const verify = async (e) => {
    e.preventDefault();
    if (verifyCode) {
      axios
        .get(
          `http://api.playit.mobi/api/v2/egypt2/fetchSubDetails?operator=${operator}&token=${token}&pincode=${verifyCode}&plancode=${signupPlan}`
        )
        .then((res) => {
         //console.log(res);
          if (res.data.status === true) {
            setVerfiErrorMesg(false);
            if (
              res.data.data.user_id &&
              res.data.data.username &&
              res.data.data.password
            ) {
              tracker(res.data.data.username);

              const data = res.data.data;
              setLoginData(
                data.user_id,
                data.token,
                data.username,
                data.photo,
                data.operator_id,
                data.user_email,
                data.msisdn
              );
              const hasAccess = confirmLogin(data.username, data.password);
             //console.log("hasAccess", hasAccess);
              if (hasAccess) {
                // alert('acess')
                history.push(`/slogin/${res.data.data.user_id}`);
                //window.location.href = "/";
              }

              // window.location.href = "/";
              ////console.log("lo" , login)
            }
            history.push("/");
            //window.location.href = "/";
          } else {
            setVerfiErrorMesg(true);
          }
        })
        .catch(function (error) {
         //console.log(error);
          setVerfiErrorMesg(true);
        })
        .finally(function () {
         //console.log("complete");
        });
    }
  };

  async function confirmLogin(username, password) {
    const hasAccess = await client.login(username, password);
    return hasAccess;
  }

  const plansOptions = plans.map((c, k) => {
    return (
      <option key={`plan-${k}`} value={c.plan}>
        {c.name}
      </option>
    );
  });

  const companiesOptions = companies.map((c, k) => {
    return (
      <option key={`company-${k}`} value={c.value}>
        {c.name}
      </option>
    );
  });

  function handleCompany(value) {
   //console.log(value);
    setsignupCompany(value);
    ////console.log(signupCompany);
    companies.filter((item) => {
      if (item.value === value) {
        setcodeCompany(item.shortCode);
      }
    });
  }

  return (
    <EgyptSubscribePageDisplay bgImg={randomBg}>
      {errorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>{errorMesgSubscribe}</Trans>
        </FloatingAlert>
      ) : null}

      {verfiErrorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>register.VERIFY_ERROR</Trans>
        </FloatingAlert>
      ) : null}

      <div className="operatorSignup  EgyoperatorSignup">
        <div className="formsLayout formsLayoutjawal formsLayoutmondia formsLayoutEgypt">
          <div className="links-Section links-Sectionjawwalgamezorid links-SectionMondia links-SectionEgy">
            <div className="mondia du-cover">
              <div to="/">
                <img src="./assets/images/Egypt/games.png" width="90%" />
              </div>
            </div>

            <h2
              className="mondiacontent ducontent egycontent"
              id="gamezoridcon"
            >
              <Trans>downloadgame</Trans>
            </h2>

            {!showverif ? (
              <div className="iconSelect EgyiconSelect">
                <select
                  style={{}}
                  className="select"
                  onChange={(e) => setSignupPlan(e.target.value)}
                >
                  {/* <option>{t('Please select your plan')}</option> */}
                  <Trans>{plansOptions}</Trans>
                </select>

                <br />
                <select
                  style={{ marginTop: "30px" }}
                  className="select"
                  onChange={(e) => handleCompany(e.target.value)}
                >
                  {/* <option>{t('Please select Your Operator')}</option> */}
                  <Trans>{companiesOptions}</Trans>
                </select>
                <br />
 
                {signupCompany !== "vodafone" ? (
                  <input
                    type="text"
                    className="input"
                    placeholder={t("Enter_your_phone_number")}
                    value={activeData}
                    onChange={(e) =>
                      setActiveData(e.target.value.replace(/[^0-9]/g, ""))
                    }
                  />
                ) : null}

                <button
                  id="signup"
                  disabled={ activeData || signupCompany == 'vodafone' ? "" : "disabled"}
                  className="btn btngamezone btnmondia btn-egypt"
                  onClick={(e) => {
                    Subscribe(e, signupCompany);
                  }}
                >
                  {t("Subscribe")}
                </button>
              </div>
            ) : null}

            {showverif ? (
              <div className="egyVerfiySection">
                <p>
                  <Trans>verifycode_or</Trans>
                </p>
                <input
                  type="text"
                  className="input"
                  value={verifyCode}
                  placeholder={t("Enter_verifycode")}
                  onChange={(e) =>
                    setVerifyCode(e.target.value.replace(/[^0-9]/g, ""))
                  }
                />
                <br></br>
                <br />
                <button
                  className="btn btngamezone btnmondia btn-egypt"
                  disabled={verifyCode ? "" : "disabled"}
                  onClick={(e) => {
                    verify(e);
                  }}
                >
                  {t("Verify")}
                </button>
              </div>
            ) : null}

            {!showverif ? (
              <div className="footer jawwalgamezoridFooter mondiaFooter duFooter">
                <div
                  className="ncellTos jawwalgamezoridncellTos mondiancellTos"
                  style={{ marginTop: "0" }}
                >
                  {/* {subtype == 'daily' ?

                  <h4><Trans>du_conditions.condition_dailyprice</Trans></h4>
                  :
                  <h4><Trans>du_conditions.condition_weeklyprice</Trans></h4>
                } */}

                  {/*<div className="row text-center content-row">
                  <div className="col-xl-3 col-lg-3 col-3">
                    <div className="games-info egy-info Egy-info butoonRound">
                      <a
                        onClick={(e) => {
                          Subscribe(e, "vodafone");
                        }}
                      >
                        <img
                          src="/assets/images/Egypt/vod2.png"
                          alt="img"
                          className="iconsimg"
                        />
                      </a>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-3">
                    <div className="games-info egy-info Egy-info butoonRound">
                      <a
                        onClick={(e) => {
                          Subscribe(e, "etisalat");
                        }}
                      >
                        <img
                          src="/assets/images/Egypt/ets.png"
                          alt="img"
                          className="iconsimg"
                          style={{ width: "5.8em" }}
                        />
                      </a>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-3">
                    <div className="games-info egy-info egy-info3 Egy-info butoonRound">
                      <a
                        onClick={(e) => {
                          Subscribe(e, "orange");
                        }}
                      >
                        <img
                          src="/assets/images/Egypt/orange.png"
                          alt="img"
                          className="iconsimg"
                          style={{ width: "5.8em" }}
                        />
                      </a>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-3">
                    <div className="games-info egy-info egy-info3 Egy-info butoonRound">
                      <a href=""
                        onClick={(e) => {
                          Subscribe(e, "we");
                        }}
                      >
                        <img
                          src="/assets/images/Egypt/we.png"
                          alt="img"
                          className="iconsimg"
                          style={{ width: "2.4em" }}
                        />
                      </a>
                    </div>
                  </div>
                      </div> */}

                  <br />

                  <div className="condtion-list">
                    {signupPlan === "d" ? (
                      <div>
                        <p>
                          <Trans>EgyptConditions.playit-daily</Trans>
                        </p>
                        <p>
                          <Trans>EgyptConditions.price-daily</Trans>
                        </p>
                      </div>
                    ) : signupPlan === "w" ? (
                      <div>
                        <p>
                          <Trans>EgyptConditions.playit-weakly</Trans>
                        </p>
                        <p>
                          <Trans>EgyptConditions.price-weakly</Trans>
                        </p>
                      </div>
                    ) : (
                      <div>
                        <p>
                          <Trans>EgyptConditions.playit-monthly</Trans>
                        </p>
                        <p>
                          <Trans>EgyptConditions.price-monthly</Trans>
                        </p>
                      </div>
                    )}

                    <ul>
                      <li>
                        <Trans>EgyptConditions.part1</Trans>
                      </li>
                      <li>
                        <Trans>EgyptConditions.part2</Trans>
                      </li>
                      <li>
                        <Trans>EgyptConditions.part3</Trans>
                        {signupPlan === "d" ? (
                          <Trans>STOP PLAYITD</Trans>
                        ) : signupPlan === "w" ? (
                          <Trans>STOP PLAYITW</Trans>
                        ) : (
                          <Trans>STOP PLAYITM</Trans>
                        )}
                        <Trans> to</Trans> {codeCompany}.
                      </li>
                      <li>
                        <Trans>EgyptConditions.part4</Trans>
                      </li>
                      <li>
                        <Trans>EgyptConditions.part5</Trans>
                      </li>
                    </ul>
                  </div>

                  <div style={{ paddingTop: "4vh" }}>
                    <h3 style={{ color: "#fff" }}>
                      <Trans>EgyConditions.part1</Trans>{" "}
                      <a href="#" className="text-cond">
                        <Trans>EgyConditions.part2</Trans>
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          {showLoading ? (
            <div className="load">
              <div className="spinerC">
                <div className="spinner"></div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </EgyptSubscribePageDisplay>
  );
}
export default withTranslation()(EgyptSubscribePage);

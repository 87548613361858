/*import FloatingAlert from "../../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../../helpers/bgSliders";
import { setAgencyData, logtracker } from "../../../helpers/agencyTracker";
import "./SignupPage.styled";
import "./Signup.scss";
import KsaSignupPageDisplay from "./SignupPage.styled";
import Modal from 'react-awesome-modal';
import i18n from "../../../i18n/i18n"
import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import axios from "axios";
import { setLoginData } from "../../../helpers/helpers";

var randomBg = bgSliders.randomBgksa();
var imgsUrlArray = [];
const localCountryCode = localStorage.getItem("countryCode");
const countryCode = localCountryCode ? localCountryCode : "EG";

const url = window.location.search

async function getBgGames() {
  try {
    const url = "http://api.playit.mobi/api/v2/playit/competition";
    const res = await axios.get(url);
    if (res) {
      var games2 = Object.values(res.data.games);
     

      for(let i of  games2 )
      {
       //console.log(i.game_photo);
        imgsUrlArray.push(i.game_photo);
        
       
        
        
      }

    //console.log("1", imgsUrlArray[0])
    //console.log("2" ,imgsUrlArray[1])
     var img1 = imgsUrlArray[0]
     var img2 = imgsUrlArray[1]
    //console.log("3" ,img1)



     this.setState({
      Bgimg1: img1,
      Bgimg2: img2

    })
      
    }
  } catch (err) {
   //console.log(err);
  }
}
if (url !== "") {
  setAgencyData(url);
  


  
}

class platItFreeSignupPage extends Component {
  constructor() {
    super();
    this.state = {
      countryCode: countryCode,
      phone: "",
      password: "",
      plan: "",
      network: "",
      error: false,
      errorMsg: "",
      windowlocation: "",
      signup: "signup",
      Bgimg1 : "http://devapi.playit.mobi/games-photos/40071_360x250.jpg",
      Bgimg2 : "http://www.api.playit.mobi/games-photos/img-20200416-5e9844887d96cget10ultimate-html5_banner_800x600.png",
      visibleModalStc: false,
      visibleModalZain: false,

    };
  }

  componentDidMount() {
    getBgGames();
    if (this.props.location.search !== "") {
      setAgencyData(this.props.location.search);
    }
    
  }

  async signup(e) {
    e.preventDefault();
    const { phone} = this.state;
    try {
      const url = `http://api.playit.mobi//api/v2/grand/subscribe?msisdn=${phone}`;
      const res =  await axios.get(url);
      if (res.data.status) {
       //console.log(res.data.data);
        if (res.data.data.user_id) {
          setLoginData(res.data.data.user_id, res.data.data.token, res.data.data.user_name, res.data.data.photo, res.data.data.operator_id,'',res.data.data.msisdn);
        }
         window.location.href = "/";
     }
        
      
    } catch (err) {
     //console.log(err);
    }
  }


  onInputChange(name, value) {
    this.setState({
      [name]: value,
      errors: false
    });
  }



 

  render() {
    const { t } = this.props;

    const { countryCode, error, errorMsg, signup ,Bgimg1, Bgimg2 } = this.state;

    return (
      <KsaSignupPageDisplay className="" bgImg="" >

        <div className="formPage">

          <div className="formsLayout">


            <div className="header">

              <Link to="/">
                <div className="logo" />
              </Link>
            </div>
            <div className="links-Section links-SectionFree">
              <div className="comBg">
                <div className="playFree">
                <h2>العب واربح</h2>
                </div>
                <div className="honers">
                  <p>جوائز تصل الى
                    <br></br>
                    <span>1000</span> 
                    <br></br> 
                  جنيه اسبوعيا</p>
                </div>
              </div>
            </div>

            <div className="center centersign">
              <br></br>
              <div className="imgs">
                <div style={{'backgroundImage': `url(${Bgimg1})`}}>

                </div>
                <div style={{'backgroundImage': `url(${Bgimg2})`}}>
                  
                </div>
              </div>
            <div className="iconInput">
              <i className="icon phone" />
              <input
                type="text"
                className="input"
                placeholder={t("enter_phone_number")}
                onChange={e => this.onInputChange("phone", e.target.value)}
              />
            </div>
            <div className="normBtn">
            <button
                    className="btn btnFree"
                    onClick={(e) => {
                      this.signup(e);
                    }}
                  >
                    {t("Signup")}
            </button>
                
              </div>
            </div>

            <div className="footer">
             
            </div>

            {error ? <FloatingAlert type="error">{errorMsg}</FloatingAlert> : ""}
          </div>
        </div>
      </KsaSignupPageDisplay>
    );
  }

}
export default withTranslation()(platItFreeSignupPage)*/


import FloatingAlert from "../../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../../helpers/bgSliders";
import { setAgencyData, logtracker } from "../../../helpers/agencyTracker";
import "./SignupPage.styled";
import "./Signup.scss";
import KsaSignupPageDisplay from "./SignupPage.styled";
import Modal from 'react-awesome-modal';
import i18n from "../../../i18n/i18n"
import { Trans, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import axios from "axios";
import { setLoginData } from "../../../helpers/helpers";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";







const randomBg = bgSliders.getRandomBg();
function platItFreeSignupPage({ t }) {
  const [activeData, setActiveData] = useState("");
  const [UrlImg1, setUrlImg1] = useState("");
  const [UrlImg2, setUrlImg2] = useState("");
  const [nameImg1, setNameImg1] = useState("");
  const [nameImg2, setNameImg2] = useState("");
  const [idImg1, setIdImg1] = useState("");
  const [idImg2, setIdImg2] = useState("");

  const [error, setErrorMesg] = useState(false);
  const history = useHistory();



  useEffect(() => {
    getBgGames();
    
  }, []);

const url = window.location.search
var imgsUrlArray = [];
var imgsNameArray = [];
var imgsIDArray = [];



 const  getBgGames = async() =>{
  try {
    const url = "http://api.playit.mobi/api/v2/playit/competition";
    const res = await axios.get(url);
    if (res) {
      var games2 = Object.values(res.data.games);
      for(let i of  games2 )
      {
       //console.log(i);
        imgsUrlArray.push(i.game_photo);
        imgsNameArray.push(i.game_name);
        imgsIDArray.push(i._id)
      }

     setUrlImg1(imgsUrlArray[0]);
     localStorage.setItem("gameComp1" , imgsUrlArray[0])
     setUrlImg2(imgsUrlArray[1]);
     localStorage.setItem("gameComp2" , imgsUrlArray[1])

     setNameImg1(imgsNameArray[0]);
     setNameImg2(imgsNameArray[1]);
     setIdImg1(imgsIDArray[0]);
     setIdImg2(imgsIDArray[1])
      
    }
  } catch (err) {
   //console.log(err);
  }
}
if (url !== "") {
  setAgencyData(url);
  


  
}

   const signup = async(e) => {         
    e.preventDefault();
    try {
      const url = `http://api.playit.mobi//api/v2/grand/subscribe?msisdn=${activeData}`;
      const res =  await axios.get(url);
      if (res.data.status) {
        if (res.data.data.user_id) {
          setLoginData(res.data.data.user_id, res.data.data.token, res.data.data.user_name, res.data.data.photo, res.data.data.operator_id,'',res.data.data.msisdn);
        }
        if (localStorage.getItem('gameURL')) {
         //console.log('gameID', localStorage.getItem('gameURL'))
          let gameURL = localStorage.getItem('gameURL');

         // history.push(gameURL)
         history.push('/leaderboard')

        } else {
          history.push('/leaderboard')
        }
     }
        
      
    } catch (err) {
     //console.log(err);
    }
  }


  const onInputChange = (name, value) => {
    setActiveData(value)
  }

  return (
    <KsaSignupPageDisplay className="" bgImg="">

    <div className="formPage">

      <div className="formsLayout">


        <div className="header headerFree">

          <Link to="/">
            <div className="logo" />
          </Link>
        </div>
        <div className="links-Section links-SectionFree">
          <div className="comBg">
            <div className="playFree">
            <h2><Trans>Play</Trans></h2>
            </div>
            <div className="honers">
              <p><Trans>awards</Trans>
                <br></br>
                <span>1000</span> 
                <br></br> 
              <Trans>Pound</Trans></p>
            </div>
          </div>
        </div>

        <div className="center centersign">
          <br></br>

          <div className="imgs">
          <div>
              <img src={UrlImg1} />


              <p>{nameImg1}</p>


            </div>

          <div>
              <img src={UrlImg2} />


              <p>{nameImg2}</p>


            </div>

           
            
          </div>
        <div className="iconInput iconInputFree">
          <i className="icon phone iconFree" />
          <input
          
            type="text"
            className="input input_phone"
            placeholder={t("enter_phone_number")}
            onChange={e => onInputChange("phone", e.target.value)}
          />
        </div>
        <div className="normBtn normBtnFree">
        <button
                className="btn btnFree"
                onClick={(e) => {
                  signup(e);
                }}
              >
                {t("playGame")}
        </button>
            
          </div>
        </div>

        <div className="ncellTos ncellTosFree">
            <h3><Trans>terms_and_conditions_competition</Trans></h3>
        </div>

       

        {error ? <FloatingAlert type="error"></FloatingAlert> : ""}
      </div>
    </div>
  </KsaSignupPageDisplay>
      
  );

}
export default withTranslation()(platItFreeSignupPage)

